import { createI18n } from "vue-i18n";
import { Locale } from "vant";
import qs from "qs";
// import EN from './en/en'
// import CN from './ZH-CN/cn'

const message = {
  'zh-CN': {
    message: {
      appDownload: "APP下载",
      downloadBtn: "下载HH88 APP",
      downloadNow: "普通下载",
      downloadNow2: "防封下载",
      jumpBtn: "若无法下载APP，点击此处使用网页版",
      guideText: "点击查看安装教学",
      installGuide: "安装教学指南",
      cancel: "取消",
      scan: "手机扫描二维码",
      download: "下载APP",
      h5: "试用网页版",
      follow: "关注我们",
      platform: "信誉平台",
      footer: "版权所有©2020-2023 HH88保留所有权",
      updateTitle: "品牌升级公告",
      dearUser: "尊敬的A51用户：",
      updateContent:
        "A51品牌已升级为HH88。原A51用户可直接登录HH88，资金和交易记录已迁移，不影响使用。如有疑问，请联系在线客服。",
      knowDetail: "了解详细>>",
      brandInfo: "全新体验 赚钱只需点一点！",
    },
    word1: "我们激动地宣布A51品牌已升级为{platform}",
    word2: "新变化包括：",
    word3: "全新品牌体验：提升程序流畅性和操作便利。",
    word4: "改善的会员福利：增加活动和优惠。",
    word5: "新增功能：提升用户体验。",
    word6:
      "原A51用户可直接用原账号登录HH88，所有资金和交易记录已安全迁移，无使用影响。如有疑问，请联系在线客服。",
    word7: "感谢您对HH88的支持与信任，我们期待在新平台上继续为您服务！",
  },
  'zh-HK': {
    message: {
      appDownload: "APP下載",
      downloadBtn: "下載HH88 APP",
      downloadNow: "普通下載",
      downloadNow2: "防封下載",
      jumpBtn: "若無法下載APP,點擊此處使用網頁版",
      guideText: "點擊查看安裝教學",
      installGuide: "安裝教學指南",
      cancel: "取消",
      scan: "手機掃描二維碼",
      download: "下載APP",
      h5: "試用網頁版",
      follow: "關注我們",
      platform: "信譽平臺",
      footer: "版權所有©2020-2023 HH88保留所有權",
      updateTitle: "品牌升級公告",
      dearUser: "尊敬的A51用戶：",
      updateContent:
        "A51品牌已升級為HH88。原A51用戶可直接登錄HH88，資金和交易記錄已遷移，不影響使用。如有疑問，請聯繫在線客服。",
      enterNow: "立即進入",
      knowDetail: "了解詳情>>",
      brandInfo: "全新體驗 賺錢只需點一點！",
    },
    word1: "我們興奮地宣布A51品牌已升級為{platform}",
    word2: "新變化包括：",
    word3: "全新品牌體驗：提升程序流暢性和操作便利。",
    word4: "改善的會員福利：增加活動和優惠。",
    word5: "新增功能：提升用戶體驗。",
    word6:
      "原A51用戶可直接用原賬號登錄HH88，所有資金和交易記錄已安全遷移，無使用影響。如有疑問，請聯繫在線客服。",
    word7: "感謝您對HH88的支持與信任，我們期待在新平台上繼續為您服務！",
  },
  'en': {
    message: {
      appDownload: "APP Download",
      downloadBtn: "Download HH88 APP",
      downloadNow: "Ordinary Download",
      downloadNow2: "Anti-blocking Download",
      jumpBtn: "Click here to use the web version",
      guideText: "Click to view installation tutorial",
      installGuide: "Installation guide",
      cancel: "Cancel",
      scan: "Scan the QR code with your phone",
      download: "Download the app",
      h5: "Try the web version",
      follow: "Follow us",
      platform: "Trusted platform",
      footer: "All rights reserved ©2020-2023 HH88",
      updateTitle: "Brand Upgrade Announcement",
      dearUser: "Dear A51 User:",
      updateContent:
        "The A51 brand has been upgraded to HH88. Original A51 users can directly log in to HH88, with funds and transaction records migrated, without affecting usage. If you have any questions, please contact online customer service.",
      enterNow: "Enter Now",
      knowDetail: "Learn More >>",
      brandInfo: "Brand new experience, make money with just one click!",
    },
    word1:
      "We are excited to announce that \nthe A51 brand has been upgraded to {platform}",
    word2: "The new changes include:",
    word3:
      "Brand new brand experience: Enhancing program smoothness and operational convenience.",
    word4: "Improved member benefits: Increase activities and offers.",
    word5: "New features: Enhance user experience.",
    word6:
      "Original A51 users can directly log in to HH88 with their existing accounts, all funds and transaction records have been safely migrated with no impact on usage. If you have any questions, please contact online customer service.",
    word7:
      "Thank you for your support and trust in HH88, we look forward to continuing to serve you on the new platform!",
  },
  'ms-MY': {
    message: {
      appDownload: "Muat turun aplikasi",
      downloadBtn: "Muat turun APP HH88",
      downloadNow: "Muat Turun Biasa",
      downloadNow2: "Muat Turun Anti-Sekatan",
      jumpBtn: "Klik di sini untuk menggunakan versi web",
      guideText: "Klik untuk melihat tutorial pemasangan",
      installGuide: "Panduan Pemasangan",
      cancel: "Batal",
      scan: "Imbas kod QR dengan telefon bimbit anda",
      download: "Muat turun aplikasi",
      h5: "Cuba versi laman web",
      follow: "Ikuti kami",
      platform: "Platform dipercayai",
      footer: "Hak cipta terpelihara ©2020-2023 HH88",
      updateTitle: "Pengumuman Penjenamaan Semula",
      dearUser: "Pengguna Yang Dihormati A51:",
      updateContent:
        "Jenama A51 telah dinaik taraf ke HH88. Pengguna asal A51 boleh log masuk terus ke HH88, dengan dana dan rekod transaksi yang dipindahkan, tanpa menjejaskan penggunaan. Jika ada pertanyaan, sila hubungi khidmat pelanggan atas talian.",
      enterNow: "Masuk Sekarang",
      knowDetail: "Ketahui Lebih Lanjut >>",
      brandInfo: "Pengalaman baharu, buat duit dengan hanya satu klik!",
    },
    word1:
      "Kami gembira mengumumkan bahawa \n jenama A51 telah dinaik taraf menjadi {platform}",
    word2: "Perubahan baru termasuk:",
    word3:
      "Pengalaman jenama yang baru: Peningkatan kelancaran program dan kemudahan operasi.",
    word4: "Manfaat ahli yang diperbaiki: Peningkatan aktiviti dan tawaran.",
    word5: "Ciri baru: Peningkatan pengalaman pengguna.",
    word6:
      "Pengguna A51 asal boleh log masuk terus ke HH88 dengan akaun sedia ada, semua dana dan rekod transaksi telah dipindahkan dengan selamat tanpa impak pada penggunaan. Jika ada sebarang pertanyaan, sila hubungi khidmat pelanggan dalam talian.",
    word7:
      "Terima kasih atas sokongan dan kepercayaan anda pada HH88, kami menantikan untuk terus berkhidmat kepada anda di platform baru!",
  },
  'vi-VN': {
    message: {
      appDownload: "TẢI APP",
      downloadBtn: "Tải ứng dụng HH88",
      downloadNow: "tải về",
      downloadNow2: "Tải xuống an toàn",
      jumpBtn: "Phiên bản máy tính",
      guideText: "Xem hướng dẫn cài đặt",
      installGuide: "Hướng dẫn cài đặt",
      cancel: "Hủy",
      scan: "Quét mã QR bằng điện thoại của bạn",
      download: "Tải ứng dụng về",
      h5: "Thử phiên bản web",
      follow: "Theo dõi chúng tôi",
      platform: "Nền tảng đáng tin cậy",
      footer: "Đã đặt mọi quyền ©2020-2023 HH88",
      updateTitle: "Thông Báo Nâng Cấp Thương Hiệu",
      dearUser: "Kính thưa Người Dùng A51:",
      updateContent:
        "Thương hiệu A51 đã được nâng cấp thành HH88. Người dùng A51 gốc có thể trực tiếp đăng nhập vào HH88, với quỹ và hồ sơ giao dịch được chuyển giao, không ảnh hưởng đến việc sử dụng. Nếu có thắc mắc, xin vui lòng liên hệ dịch vụ khách hàng trực tuyến.",
      enterNow: "Truy cập",
      knowDetail: "Tìm Hiểu Thêm >>",
      brandInfo:
        "Trải nghiệm hoàn toàn mới, kiếm tiền chỉ bằng một cú nhấp chuột!",
    },
    word1:
      "Chúng tôi phấn khích thông báo rằng \n thương hiệu A51 đã được nâng cấp thành {platform}",
    word2: "Những thay đổi mới bao gồm:",
    word3:
      "Trải nghiệm thương hiệu hoàn toàn mới: Nâng cao sự mượt mà của chương trình và tiện lợi trong thao tác.",
    word4:
      "Lợi ích thành viên được cải thiện: Tăng cường các hoạt động và ưu đãi.",
    word5: "Tính năng mới: Nâng cao trải nghiệm người dùng.",
    word6:
      "Người dùng A51 gốc có thể đăng nhập trực tiếp vào HH88 với tài khoản hiện tại, tất cả tài chính và hồ sơ giao dịch đã được chuyển giao an toàn mà không ảnh hưởng đến việc sử dụng. Nếu có thắc mắc, vui lòng liên hệ dịch vụ khách hàng trực tuyến.",
    word7:
      "Cảm ơn sự ủng hộ và tin tưởng của bạn đối với HH88, chúng tôi mong muốn tiếp tục phục vụ bạn trên nền tảng mới!",
  },
  'hi-IN': {
    message:{
    appDownload: "एप्लिकेशन डाउनलोड",
    downloadBtn: "HH88 एप्लिकेशन डाउनलोड करें",
    downloadNow: "सामान्य डाउनलोड",
    downloadNow2: "अवरोध रहित डाउनलोड",
    jumpBtn: "अगर एप्लिकेशन डाउनलोड नहीं हो सकता है, तो वेब संस्करण का उपयोग करने के लिए यहां क्लिक करें",
    guideText: "स्थापना शिक्षण देखने के लिए क्लिक करें",
    installGuide: "स्थापना शिक्षण मार्गदर्शिका",
    cancel: "रद्द करें",
    scan: "मोबाइल से क्यूआर कोड स्कैन करें",
    download: "एप्लिकेशन डाउनलोड करें",
    h5: "वेब संस्करण का उपयोग करें",
    follow: "हमारा अनुसरण करें",
    platform: "विश्वसनीय प्लेटफ़ॉर्म",
    footer: "कॉपीराइट©2020-2023 HH88 सभी अधिकार सुरक्षित हैं",
    updateTitle: "ब्रांड अपग्रेड घोषणा",
    dearUser: "प्रिय A51 उपयोगकर्ता:",
    updateContent:
      "A51 ब्रांड को HH88 में अपग्रेड किया गया है। मूल A51 उपयोगकर्ता सीधे HH88 में लॉग इन कर सकते हैं, धन और लेनदेन रिकॉर्ड सुरक्षित रूप से स्थानांतरित कर दिए गए हैं और उपयोग में कोई बाधा नहीं होगी। किसी भी प्रश्न के लिए, कृपया ऑनलाइन सहायता से संपर्क करें।",
    knowDetail: "विस्तृत जानकारी जानें>>",
    brandInfo: "नया अनुभव, केवल एक क्लिक में कमाई!",
  },
  word1: "हम गर्व के साथ घोषणा करते हैं कि A51 ब्रांड को {platform} में अपग्रेड कर दिया गया है",
  word2: "नए बदलाव में शामिल हैं:",
  word3: "नया ब्रांड अनुभव: कार्यक्रम की सहज प्रवाहिता और संचालन की सुविधा में सुधार।",
  word4: "बेहतर सदस्य लाभ: गतिविधियों और छूट में वृद्धि।",
  word5: "नई सुविधाएं: उपयोगकर्ता अनुभव को बढ़ाया।",
  word6:
    "मूल A51 उपयोगकर्ता सीधे A51 खाते से HH88 में लॉग इन कर सकते हैं, सभी धन और लेनदेन रिकॉर्ड सुरक्षित रूप से स्थानांतरित कर दिए गए हैं और उपयोग में कोई बाधा नहीं होगी। किसी भी प्रश्न के लिए, कृपया ऑनलाइन सहायता से संपर्क करें।",
  word7: "HH88 का समर्थन और विश्वास करने के लिए धन्यवाद, हम नए प्लेटफॉर्म पर आपकी सेवा करते रहने की उम्मीद करते हैं!"
}
};

let parse = qs.parse(window.location.search.substring(1));
let lang = parse.lang;
if (!lang) {
  if (
    navigator.language === "zh" ||
    navigator.language === "zh-CN" ||
    navigator.language === "zh-SG" ||
    navigator.language === "zh-MO" ||
    navigator.language === "zh-Hant"
  ) {
    lang = "zh-CN";
  } else if (navigator.language === "zh-HK" || navigator.language === "zh-TW") {
    lang = "zh-HK";
  } else if (
    navigator.language === "ms" ||
    navigator.language === "mg" ||
    navigator.language === "ms-MY" ||
    navigator.language === "ms-BN"
  ) {
    lang = "ms-MY";
  } else if (navigator.language === "vi" || navigator.language === "vi_VN") {
    lang = "vi-VN";
  } else {
    lang = "en";
  }
}
const i18n = createI18n({
  locale: lang, // 设置语言类型
  legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
  globalInjection: true, // 全局注册$t方法
  messages: message,
});

const messages = {
  "zh-CN": {
    vanPicker: {
      confirm: "关闭", // 将'确认'修改为'关闭'
    },
  },
};

Locale.add(messages);

export default i18n;
