import axios from 'axios'
import qs from "qs";
let parse = qs.parse(window.location.search.substring(1));
let lang = parse?.lang;
if (!lang) {
  if (
    navigator.language === "zh" ||
    navigator.language === "zh-CN" ||
    navigator.language === "zh-SG" ||
    navigator.language === "zh-MO" ||
    navigator.language === "zh-Hant"
  ) {
    lang = "zh-CN";
  } else if (navigator.language === "zh-HK" || navigator.language === "zh-TW") {
    lang = "zh-HK";
  } else if (
    navigator.language === "ms" ||
    navigator.language === "mg" ||
    navigator.language === "ms-MY" ||
    navigator.language === "ms-BN"
  ) {
    lang = "ms-MY";
  } else if (navigator.language === "vi" || navigator.language === "vi_VN") {
    lang = "vi-VN";
  } else {
    lang = "en";
  }
}

const service = axios.create({
  baseURL: '', // api 的 base_url
  timeout: 60000 // 请求超时时间
})
// request 拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    config.headers['domain'] = 'localhost:3000';
    // site
    config.headers['site'] = parse.site ||  'usd'
    // lang
    config.headers['lang'] = lang;
    // 根据参数传入
    return config
  },
  error => {
    // 对请求错误做些什么
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    // 如果返回的状态码不是 200，则判断为错误
    if (res.code !== '0000') {
      console.log('Error: ', res.msg)
    //   return Promise.reject(new Error(res.msg))

    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service;